import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useInterceptor } from 'hooks/useInterceptor';
import { RecoilRoot } from 'recoil';
import MainRoutes from 'routes';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  useInterceptor();

  return (
    <BrowserRouter>
      <RecoilRoot>
        <MainRoutes />
        <ToastContainer className='text-sm bottom-20' stacked={true} />
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
