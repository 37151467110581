import { FC, lazy, memo, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PROTECTED_ROUTES, PUBLIC_ROUTES } from 'constants/routes';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import ResetPassword from 'pages/Auth/ResetPassword';
import VerifyOtp from 'pages/Auth/VerifyOtp';
import BlockRegistrant from 'pages/BlockRegistrant';
import { showToast } from 'utils/toast';

const SitePages = lazy(() => import('pages/SitePages'));
const ShortUrlPage = lazy(() => import('pages/ShortUrlPage'));
const Page404 = lazy(() => import('pages/Page404'));
const SiteLayout = lazy(() => import('pages/SiteLayout'));
const Survey = lazy(() => import('pages/Survey'));

const MainRoutes: FC = () => {
  const sessionMessage = localStorage.getItem('sessionMessage');
  if (sessionMessage) {
    showToast({ message: sessionMessage, type: 'info' });
    localStorage.clear();
  }

  return (
    <Suspense>
      <Routes>
        <Route path='/:shortUrl' element={<ShortUrlPage />} />
        <Route path='/' element={<SiteLayout />}>
          <Route
            path={`${PROTECTED_ROUTES.BASE_ROUTE}/survey`}
            element={<Survey />}
          />
          <Route
            path={`${PROTECTED_ROUTES.BASE_ROUTE}/*`}
            element={<SitePages />}
          />
          <Route
            path={PUBLIC_ROUTES.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
          <Route path={PUBLIC_ROUTES.VERIFY_OTP} element={<VerifyOtp />} />
          <Route
            path={PUBLIC_ROUTES.RESET_PASSWORD}
            element={<ResetPassword />}
          />
        </Route>
        <Route
          path={PUBLIC_ROUTES.BLOCK_REGISTRANT}
          element={<BlockRegistrant />}
        />
        <Route path={PUBLIC_ROUTES.NOT_FOUND} element={<Page404 />} />
        <Route path='*' element={<Page404 />} />
      </Routes>
    </Suspense>
  );
};

export default memo(MainRoutes);

MainRoutes.displayName = 'MainRoutes';
