import { useCallback, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { FaCheck } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import Input from 'components/Inputs';
import { CreateRegistrantForm } from 'types/registrant';

type UpdatePasswordFieldsProps = {
  control: Control<CreateRegistrantForm>;
  isLoading: boolean;
  passwordValue: string;
};

export const UpdatePasswordFields: React.FC<UpdatePasswordFieldsProps> = ({
  control,
  isLoading,
  passwordValue,
}) => {
  const checkRequirements = useCallback(() => {
    const hasLength = passwordValue?.length >= 8;
    const hasLowercase =
      typeof passwordValue === 'string' && /[a-z]/.test(passwordValue);
    const hasUppercase = /[A-Z]/.test(passwordValue);
    const hasNumber = /\d/.test(passwordValue);
    const hasSymbol = /[#?!@$%^&*-]/.test(passwordValue);

    return [hasLength, hasLowercase, hasUppercase, hasNumber, hasSymbol];
  }, [passwordValue]);

  const renderIcon = useCallback((condition?: boolean) => {
    return condition ? <FaCheck /> : <MdClose />;
  }, []);

  const renderListItem = useCallback(
    (text: string, condition?: boolean) => {
      const textColor = condition ? 'text-green-600' : 'text-red-600';
      return (
        <li className={`flex flex-row items-center ${textColor}`}>
          <span className='mr-1'>{renderIcon(condition)}</span> {text}
        </li>
      );
    },
    [renderIcon],
  );

  const [hasLength, hasLowercase, hasUppercase, hasNumber, hasSymbol] = useMemo(
    () => checkRequirements(),
    [checkRequirements],
  );

  return (
    <div className='flex w-full mt-3 gap-3 md:flex-row flex-col'>
      <div className='w-full'>
        <Controller
          control={control}
          name='password'
          defaultValue=''
          render={({ fieldState: { error }, field }) => (
            <Input
              autoComplete='new-password'
              error={{ message: !!error?.message }}
              name={field.name}
              value={field.value}
              disabled={isLoading}
              onChange={field.onChange}
              label='Password'
              type='password'
            />
          )}
        />
        <div className='text-xs'>
          <p className='mt-2 mb-1'>Password must contain:</p>
          <ul className='list-inside'>
            {renderListItem('8 characters', hasLength)}
            {renderListItem('1 lowercase character', hasLowercase)}
            {renderListItem('1 uppercase character', hasUppercase)}
            {renderListItem('1 number', hasNumber)}
            {renderListItem('1 symbol', hasSymbol)}
          </ul>
        </div>
      </div>

      <Controller
        control={control}
        name='confirmpassword'
        defaultValue=''
        render={({ fieldState: { error }, field }) => (
          <Input
            error={error}
            name={field.name}
            value={field.value}
            disabled={isLoading}
            onChange={field.onChange}
            type='password'
            label='Confirm Password'
            autoComplete='new-password'
          />
        )}
      />
    </div>
  );
};
