import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LOCAL_CONSTANT } from 'constants/auth';
import { API_ROUTES } from 'constants/routes';
import { useRequest } from 'hooks/useRequest';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { OtpPayload, TokenPayload } from 'types/auth';

export default function VerifyOtp() {
  const { control } = useForm<OtpPayload>();
  const { post } = useRequest();
  const navigate = useNavigate();
  const { accountId, eventGroupId } = useParams();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const onSubmit: SubmitHandler<OtpPayload> = async ({ otp }) => {
    const res = await post<TokenPayload, OtpPayload>(
      `${API_ROUTES.REGISTRANT}/verify-reset-password`,
      { otp: Number(otp) },
      {},
      { showToast: true, errorMessage: 'Invalid pin' },
    );

    if (!res.error) {
      localStorage.setItem(
        LOCAL_CONSTANT.RESET_TOKEN,
        res.data?.accessToken ?? '',
      );

      navigate(`/reset-password/${eventGroupId}/${accountId}?email=${email}`);
    }
  };

  return (
    <div className='md:w-1/2 mx-auto mt-40'>
      <div className='flex flex-col items-center'>
        <div className='sm:flex sm:items-center mb-6'>
          <div className='sm:flex-auto'>
            <h1 className='text-xl font-semibold leading-6 text-gray-900'>
              Verify your email
            </h1>
            <p className='mt-2 text-sm text-gray-800'>
              Please check your email for a one-time pin.
            </p>
          </div>
        </div>
      </div>
      <div className='w-[80%] md:w-[60%] mx-auto mt-2'>
        <Controller
          control={control}
          name='otp'
          defaultValue=''
          rules={{
            required: {
              message: 'Please enter a otp',
              value: true,
            },
            pattern: {
              value: /[0-9]{5}/,
              message: 'Otp should be a valid number',
            },
          }}
          render={({ field, fieldState }) => (
            <div className='flex justify-center'>
              <MuiOtpInput
                {...field}
                value={field.value as string}
                sx={{ gap: 1 }}
                length={5}
                width={300}
                TextFieldsProps={{
                  size: 'medium',
                  type: 'text',
                }}
                onComplete={(otp) => {
                  onSubmit({ otp });
                }}
              />
              {fieldState.error ? <p>{fieldState.error.message}</p> : null}
            </div>
          )}
        />
      </div>
    </div>
  );
}
