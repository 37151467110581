import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'components/Buttons/Index';
import { LOCAL_CONSTANT } from 'constants/auth';
import { API_ROUTES, PROTECTED_ROUTES } from 'constants/routes';
import { useRequest } from 'hooks/useRequest';
import { useSetRecoilState } from 'recoil';
import { authState, currentUserState } from 'state/atoms';
import { NewPasswordPayload } from 'types/auth';
import {
  CreateRegistrantForm,
  RegistrantLoginPayload,
  RegistrantLoginResponse,
} from 'types/registrant';
import { handleInjectParams } from 'utils/route.util';

import { UpdatePasswordFields } from './Register/UpdatePasswordFields';

export default function ResetPassword() {
  const { control, handleSubmit, watch } = useForm<CreateRegistrantForm>();
  const { post, isLoading } = useRequest();
  const navigate = useNavigate();
  const { accountId, eventGroupId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const setAuth = useSetRecoilState(authState);
  const setCurrentUser = useSetRecoilState(currentUserState);
  const token = searchParams.get('token');

  const passwordValue = watch('password');

  React.useEffect(() => {
    if (token) {
      localStorage?.setItem(LOCAL_CONSTANT.RESET_TOKEN, token ?? '');
    }
  }, [token]);

  const onSubmit: SubmitHandler<CreateRegistrantForm> = async ({
    password,
  }) => {
    const res = await post<boolean, NewPasswordPayload>(
      `${API_ROUTES.REGISTRANT}/reset-password`,
      { newpassword: password },
      {
        Authorization: `Bearer ${
          localStorage?.getItem(LOCAL_CONSTANT.RESET_TOKEN) ?? ''
        }`,
      },
      {
        showToast: true,
        successMessage: 'Your password is successfully updated.',
      },
    );
    if (res.error) {
      return;
    }

    localStorage.removeItem(LOCAL_CONSTANT.RESET_TOKEN);

    if (email) {
      const { error, data } = await post<
        RegistrantLoginResponse,
        RegistrantLoginPayload
      >(`${API_ROUTES.REGISTRANT}/login`, {
        email,
        password,
        accountId: accountId ?? '',
        eventGroupId,
      });

      if (!error && data) {
        setAuth(data?.accessToken);
        setCurrentUser(data.registrant);
      }
    }

    navigate(
      handleInjectParams(`${PROTECTED_ROUTES.BASE_ROUTE}/home`, {
        accountId,
        eventGroupId,
      }),
    );
  };

  return (
    <div className='md:w-[400px] mx-auto mt-40'>
      <div className='sm:flex sm:items-center mb-6'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl font-semibold leading-6 text-gray-900'>
            Reset Password
          </h1>
          <p className='mt-2 text-sm text-gray-800'>
            Please enter your new password to continue.
          </p>
        </div>
      </div>
      <div className='flex flex-col gap-3 mt-5'>
        <UpdatePasswordFields
          control={control}
          isLoading={isLoading}
          passwordValue={passwordValue}
        />
        <div className='flex justify-end mt-3'>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            loading={isLoading}
            type='submit'
            label='Reset Password'
          />
        </div>
      </div>
    </div>
  );
}
