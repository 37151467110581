import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { HeaderConstants } from 'constants/common';
import { AccountRequiredRoutes } from 'constants/routes';
import { API } from 'services/api';
import { IResponse } from 'types/response';
import { SerializeResponse } from 'utils/serializer';
import { showToast } from 'utils/toast';

const DEFAULTS = {
  headers: {
    'Content-Type': 'application/json',
  },
};

type RequestOptionsType = {
  showToast?: boolean;
  successMessage?: string;
  errorMessage?: string;
};

export const useRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { accountId } = useParams();

  const handleResponseMessage = useCallback(
    <RT>(res: IResponse<RT>, options?: RequestOptionsType) => {
      const {
        errorMessage,
        showToast: hideToast = true,
        successMessage,
      } = options ?? {};
      if (!hideToast) {
        return res;
      }
      if (res.error) {
        return showToast({
          message: errorMessage ?? res.message,
          type: 'error',
        });
      }
      if (successMessage) {
        return showToast({ message: successMessage, type: 'success' });
      }
    },
    [],
  );

  const injectHeaders = useCallback(
    (endpoint: string) => {
      if (
        AccountRequiredRoutes.some((val) => endpoint.includes(val)) &&
        accountId
      ) {
        return {
          [HeaderConstants.ACCOUNT_ID]: accountId,
        };
      }
      return {};
    },
    [accountId],
  );

  const get = useCallback(
    async <RT>(endpoint: string, options?: RequestOptionsType) => {
      setIsLoading(true);
      const res = API.get<IResponse<RT>>(endpoint, {
        ...DEFAULTS,
        headers: {
          ...DEFAULTS.headers,
          ...injectHeaders(endpoint),
        },
      });

      const resJson = await SerializeResponse<RT>(res);

      setIsLoading(false);
      handleResponseMessage(resJson, options);
      return resJson;
    },
    [handleResponseMessage, injectHeaders],
  );

  const post = useCallback(
    async <RT, PT>(
      endpoint: string,
      body: PT,
      headers?: Record<string, string>,
      options?: RequestOptionsType,
    ) => {
      setIsLoading(true);
      const res = API.post<PT, AxiosResponse<IResponse<RT>>>(endpoint, body, {
        ...DEFAULTS,
        headers: {
          ...DEFAULTS.headers,
          ...headers,
          ...injectHeaders(endpoint),
        },
      });

      const resJson = await SerializeResponse<RT>(res);
      setIsLoading(false);
      handleResponseMessage(resJson, options);
      return resJson;
    },
    [handleResponseMessage, injectHeaders],
  );

  const put = useCallback(
    async <RT, PT>(
      endpoint: string,
      body: PT,
      options?: RequestOptionsType,
    ) => {
      setIsLoading(true);
      const res = API.put<PT, AxiosResponse<IResponse<RT>>>(endpoint, body, {
        ...DEFAULTS,
        headers: {
          ...DEFAULTS.headers,
          ...injectHeaders(endpoint),
        },
      });
      const resJson = await SerializeResponse<RT>(res);
      setIsLoading(false);
      handleResponseMessage(resJson, options);
      return resJson;
    },
    [handleResponseMessage, injectHeaders],
  );

  const patch = useCallback(
    async <RT, PT = unknown>(
      endpoint: string,
      body?: PT,
      options?: RequestOptionsType,
    ) => {
      setIsLoading(true);
      const res = API.patch<PT, AxiosResponse<IResponse<RT>>>(endpoint, body, {
        ...DEFAULTS,
        headers: {
          ...DEFAULTS.headers,
          ...injectHeaders(endpoint),
        },
      });
      const resJson = await SerializeResponse<RT>(res);
      setIsLoading(false);
      handleResponseMessage(resJson, options);
      return resJson;
    },
    [handleResponseMessage, injectHeaders],
  );

  // cannot use function name delete as it is reserved word
  const deleteRequest = useCallback(
    async <RT>(endpoint: string, options?: RequestOptionsType) => {
      setIsLoading(true);
      const res = API.delete<IResponse<RT>>(endpoint, {
        ...DEFAULTS,
        headers: {
          ...DEFAULTS.headers,
          ...injectHeaders(endpoint),
        },
      });
      const resJson = await SerializeResponse<RT>(res);
      setIsLoading(false);
      handleResponseMessage(resJson, options);
      return resJson;
    },
    [handleResponseMessage, injectHeaders],
  );

  return useMemo(
    () => ({ get, post, put, delete: deleteRequest, patch, isLoading }),
    [deleteRequest, get, isLoading, patch, post, put],
  );
};
