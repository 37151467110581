import { LOCAL_CONSTANT } from 'constants/auth';
import { atom, AtomEffect } from 'recoil';
import { MessageState } from 'types/common';
import { EventGroup, EventSite } from 'types/eventsite';
import { Registrant } from 'types/registrant';
import { EventVenue } from 'types/venues';

// For persisting the state of recoil
export const localStorageEffect: <T>(
  key: string,
  stringify?: boolean,
) => AtomEffect<T> =
  (key: string, stringify = true) =>
  ({ onSet, setSelf }) => {
    const savedValue = localStorage.getItem(key);
    // eslint-disable-next-line eqeqeq
    if (savedValue != null) {
      setSelf(stringify ? JSON.parse(savedValue) : savedValue);
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(
            key,
            stringify
              ? JSON.stringify(newValue)
              : (newValue as unknown as string),
          );
    });
  };

export const currentUserState = atom<Registrant | null>({
  key: LOCAL_CONSTANT.USER,
  default: null,
  effects: [],
});

export const eventGroupState = atom<EventGroup | null>({
  key: LOCAL_CONSTANT.EVENT_GROUP,
  default: null,
  effects: [],
});

export const eventVenuesState = atom<EventVenue[] | null>({
  key: LOCAL_CONSTANT.VENUES,
  default: null,
  effects: [],
});

export const authState = atom<string | null>({
  key: LOCAL_CONSTANT.USER_TOKEN,
  default: '',
  effects: [
    localStorageEffect<string | null>(LOCAL_CONSTANT.USER_TOKEN, false),
  ],
});

export const toastState = atom<MessageState>({
  key: 'toast',
  default: { status: false, message: '', variant: 'success' },
});

export const eventSiteState = atom<EventSite | null>({
  key: 'EventSite',
  default: null,
});

export const isLoadingState = atom<boolean>({
  key: 'Loading',
  default: true,
});
