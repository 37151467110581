import { LOCAL_CONSTANT } from 'constants/auth';
import { API } from 'services/api';

const PREVENT_RELOAD_ROUTES = [
  'verify-reset-password',
  'login-registrant-by-token',
];

export const useInterceptor = () => {
  API.interceptors.request.use((config) => {
    const token = localStorage.getItem(LOCAL_CONSTANT.USER_TOKEN);
    if (config?.headers && token) {
      config.headers.Authorization = token ? `Bearer ${token}` : '';
    }
    return config;
  });

  // TODO: Handle errors and success and set toast messages
  API.interceptors.response.use(
    (response) => response,
    (error) => {
      switch (error.response?.status) {
        case 401: {
          localStorage.clear();
          localStorage.setItem(
            'sessionMessage',
            'Your session has expired. Please sign-in again.',
          );

          const isReloadRequired = !PREVENT_RELOAD_ROUTES.some((route) =>
            error.config.url.includes(route),
          );

          if (isReloadRequired) {
            window.location.reload();
          }
          return Promise.reject(error);
        }
        case 403: {
          setTimeout(() => {}, 100);
          return Promise.reject(error);
        }
        default: {
          return Promise.reject(error);
        }
      }
    },
  );
};
