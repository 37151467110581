/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { FieldError } from 'react-hook-form';
import classNames from 'classnames';

import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

interface InputPropTypes {
  label: string;
  type: 'text' | 'password' | 'email' | 'number';
  name: string;
  placeholder?: string;
  value: string;
  onChange: (...event: any[]) => void;
  error: FieldError | { message: boolean } | undefined;
  icon?: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, 'ref'>
  >;
  disabled?: boolean;
  autoComplete?: string;
  required?: boolean;
  autoFocus?: boolean;
  disableAutoFill?: boolean;
  containerClassName?: string;
  inputHeight?: string;
}

const Input: React.FC<InputPropTypes> = ({
  label,
  type,
  name,
  placeholder,
  value,
  error,
  onChange,
  icon: Icon,
  disabled = false,
  autoComplete = 'off',
  required = false,
  autoFocus = false,
  disableAutoFill = false,
  containerClassName = 'w-full',
  inputHeight = 'h-10',
}) => {
  const [readOnly, setReadOnly] = useState(true);

  return (
    <div className={containerClassName}>
      <div className='flex justify-between'>
        <label
          htmlFor='common-input'
          className='block text-sm font-medium leading-6 text-gray-800'
        >
          {label}
        </label>

        {required ? (
          <span className='text-xs leading-6 text-gray-500 pr-1'>Required</span>
        ) : null}
      </div>

      <div className='relative mt-1 rounded-md shadow-sm'>
        {Icon ? (
          <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
            <Icon className='h-5 w-5 text-gray-400' aria-hidden='true' />
          </div>
        ) : null}

        <input
          role='presentation'
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          autoSave='false'
          disabled={disabled}
          type={type}
          name={name}
          value={value}
          id={`${label}-${name}`}
          className={classNames(
            `block ${inputHeight} w-full rounded-md border-0 py-1 text-gray-800 ring-1 ring-inset ring-gray-300 placeholder:text-gray-40 focus:ring-inset sm:text-sm sm:leading-6 focus:outline-primary`,
            {
              'ring-red-300': !!error?.message,
              'pl-10': !!Icon,
              'pl-4': !Icon,
            },
          )}
          placeholder={placeholder}
          onChange={onChange}
          readOnly={disableAutoFill ? readOnly : false}
          onTouchStart={() => {
            if (disableAutoFill) setReadOnly(false);
          }}
          onFocus={() => {
            if (disableAutoFill) setReadOnly(false);
          }}
          onBlur={() => {
            if (disableAutoFill) setReadOnly(true);
          }}
        />
        {error?.message ? (
          <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
            <ExclamationCircleIcon
              className='h-5 w-5 text-red-500'
              aria-hidden='true'
            />
          </div>
        ) : null}
      </div>

      {error?.message ? (
        <p className='mt-2 text-xs text-red-600' id='email-error'>
          {error.message}
        </p>
      ) : null}
    </div>
  );
};

export default Input;
