// import { PROTECTED_ROUTES } from 'constants/routes';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const handleInjectParams = (route: any, params: any) => {
  const paramKeys = Object.keys(params);
  let updatedRoute = route as string;

  paramKeys.forEach((paramKey) => {
    const paramValue = params[paramKey];
    const paramPlaceholder = `:${paramKey}`;
    updatedRoute = updatedRoute.replace(paramPlaceholder, paramValue ?? '');
  });
  return updatedRoute;
};

export const removePrefixRelativeUrl = (relativeUrl: string) =>
  relativeUrl.replace('/', '');
