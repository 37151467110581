import React, { useCallback, useEffect, useState } from 'react';
import { FcHighPriority } from 'react-icons/fc';
import { useSearchParams } from 'react-router-dom';
import { FullScreenLoader } from 'components/Loaders/FullScreenLoader';
import { API_ROUTES } from 'constants/routes';
import { useRequest } from 'hooks/useRequest';

const BlockRegistrant = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [loading, setLoading] = useState(false);
  const { post } = useRequest();

  const blockRegistrant = useCallback(async () => {
    if (token) {
      setLoading(true);
      await post(
        `${API_ROUTES.REGISTRANT}/block-registrant-email`,
        {},
        { Authorization: `Bearer ${token}` },
      );
      setLoading(false);
    }
  }, [post, token]);

  useEffect(() => {
    blockRegistrant();
  }, [blockRegistrant, token]);

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <div className='w-full mt-10 flex h-[75vh] flex-col items-center justify-center'>
      <FcHighPriority size={80} />
      <p className='text-xl text-center mt-3'>
        Your email is now blocked from this platform.
      </p>
    </div>
  );
};

export default BlockRegistrant;
